import { EnhancedWithAuthHttpService } from '@/common/services/http-auth.service';
import { axiosInstance } from '@/common/services/axios';
import { HttpService } from '@/common/services/http.service';
import {
    FollowedQuery,
    IAddQueryAnswerPayload,
    IQuestion,
    IQuestionPayload,
    IQuestionProfileResponse,
    ISentiment,
    ISentimentSwings
} from '@/common/types/question.type';
import { IPost } from '../types/post.type';
import { IAddPollAnswerPayload, IPollFilterPayload, IPollPayload, IPollsList, IPollSwings } from '../types/poll.types';

class PollService extends EnhancedWithAuthHttpService {
    constructor() {
        super(new HttpService(axiosInstance));
    }

    async getSectorQuestions() {
        return this.get<IQuestion[]>('poll/sector');
    }

    async getCompaniesQuestions() {
        return this.get<IQuestion[]>('poll/company');
    }

    async getQueriesQuestions() {
        return this.get<IQuestion[]>('poll/query');
    }

    async getSentimentProfileQuestions(profileId: string) {
        return this.get<IQuestionProfileResponse>(`poll/sentiment-profile/${profileId}`);
    }

    async getSentimentHomeQuestions() {
        return this.get<ISentiment[]>('poll/sentiment-home');
    }

    async getSentimentSwings({ page, limit, search, periods, regions, sectors, categories }: IPollFilterPayload) {
        const optionsUrl = new URLSearchParams();
        optionsUrl.append('page', page ? page.toString() : '1');
        optionsUrl.append('limit', limit ? limit.toString() : '20');
        optionsUrl.append('search', search ? search : '');
        if (periods && periods.length > 0) {
            periods.forEach((value) => {
                optionsUrl.append('periods', value.toString());
            });
        }
        if (regions && regions.length > 0) {
            regions.forEach((value) => {
                optionsUrl.append('regions', value);
            });
        }
        if (sectors && sectors.length > 0) {
            sectors.forEach((value) => {
                optionsUrl.append('sectors', value);
            });
        }
        if (categories && categories.length > 0) {
            categories.forEach((value) => {
                optionsUrl.append('categories', value);
            });
        }
        return this.get<IPollsList>(`poll/poll-sentiment-swings?${optionsUrl}`);
    }

    async getAvailableFilters({ search, periods, regions, sectors, categories }: Omit<IPollFilterPayload , 'limit' | 'page'>) {
        const optionsUrl = new URLSearchParams();
        optionsUrl.append('search', search ? search : '');
        if (periods && periods.length > 0) {
            periods.forEach((value) => {
                optionsUrl.append('periods', value.toString());
            });
        }
        if (regions && regions.length > 0) {
            regions.forEach((value) => {
                optionsUrl.append('regions', value);
            });
        }

        if (sectors && sectors.length > 0) {
            sectors.forEach((value) => {
                optionsUrl.append('sectors', value);
            });
        }
        if (categories && categories.length > 0) {
            categories.forEach((value) => {
                optionsUrl.append('categories', value);
            });
        }
        return this.get<{ periods: number[]; regions: string[]; sectors: string[]; categories: string[] }>(`poll/avaiable-filters?${optionsUrl}`,);
    }

    async getAllFilters() {
        return this.get<{ periods: number[]; regions: string[] }>(`poll/all-filters`);
    }

    async getPreselectedFilters() {
        return this.get<{ sectors: string[]; regions: string[]; categories: string[] }>(`poll/preselected-filters`);
    }

    async createQuestion(poll: IPollPayload) {
        return this.post<IPost, IPollPayload>('poll', poll);
    }

    async addQueryAnswer(answer: IAddPollAnswerPayload) {
        return this.post<{ post: IPost; pollId: string }, IAddPollAnswerPayload>('poll/answer', answer);
    }

    async getFollowedQueries() {
        return this.get<FollowedQuery[]>('poll/followed-queries');
    }

    async getQueryAnswer(pollId: string) {
        return this.get<number>(`poll/answer/${pollId}`);
    }
}

export const pollService = new PollService();
