import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { authService, userService } from '@services';
import posthog from 'posthog-js';
import { QUERY_KEYS, ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { setIsAnonUser, setIsNonFinanceUser, useUser } from '@/common/hooks/use-user';
import { IUserPayload } from '../types/user.type';

import {
  IPasswordlessRequestPayload,
  IPasswordlessSendCodePayload,
  IRecoveryPassword,
  IRegisterUserPayload,
  IResetAnonKeywordRequestPayload
} from '../types/auth.type';
import { authToAnonService } from '../services/auth-to-anon.service';
import { IEmailVerifyConfirmPayload, IEmailVerifySendPayload } from '../types/anon.types';
import { wait } from '../utils/wait';
import { useDialogs } from './use-direst-messages';

export const useLogount = () => {
  const { disconnectUser } = useDialogs();
  const { push } = useRouter();
  const queryClient = useQueryClient();
  return useMutation(() => authService.logout(), {
    onSuccess: async () => {
      await wait(500);
      await authService.logout();
      posthog.reset();
      await push(ROUTER_KEYS.AUTH);
      localStorage.clear();
      disconnectUser();
      queryClient.clear();
    }
  });
};

export const useCheck = () =>
  useQuery({
    queryKey: [QUERY_KEYS.CHECK],
    queryFn: () => authService.check(),
    onSuccess: async (check: boolean) => {
      if (check) {
        return check;
      }
    }
  });

export const triedToActivate = () => authService.triedToActivate();

export const useAuth = () => {
  const user = useUser();
  const { push } = useRouter();
  const queryClient = useQueryClient();
  const setNewIsAnonUser = setIsAnonUser();
  const setNewIsNonFinanceUser = setIsNonFinanceUser();
  const { mutateAsync: logout } = useLogount();

  const {
    data,
    isLoading: isUserLoading,
    refetch: getMe
  } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: () => userService.me(),
    onSuccess: async (me) => {
      if (!me) {
        logout();
      }
      if (me) {
        setNewIsAnonUser(me.isAnon);
        setNewIsNonFinanceUser(me.isNonFinance);
        queryClient.invalidateQueries([QUERY_KEYS.PROFILE, 'auth']);
      }
    },
    retry: 3,
    retryDelay: 600,
    staleTime: 500,
    onError: () => {
      logout();
    }
  });

  const { data: check, isLoading: isCheckLoading } = useCheck();

  useEffect(() => {
    if (!user || !check) getMe();

    if (user) {
      posthog.identify(user.email, { email: user.email });
      if (!user?.setupStep || user?.setupStep === null || (user.isAnon && user.setupStep <= 2)) {
        setNewIsAnonUser(user.isAnon);
        setNewIsNonFinanceUser(user.isNonFinance);
        localStorage.setItem('anonVerify', 'false');
        // push(ROUTER_KEYS.PULL);
      } else if (user?.setupStep === 0) {
        push(ROUTER_KEYS.ROOT);
      }
    }
  }, [user, check, isCheckLoading]);
  return { isUserLoading, user: user ?? data, getMe };
};

export const useVerifyAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (token: string) => authService.verifyAccount(token),
    onSuccess: (data) => {
      if (!data) return;
      queryClient.setQueryData([QUERY_KEYS.USER], data);
    }
  });
};

export const useLoginAccount = (onError?: (err: unknown) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUserPayload) => authService.signIn(data),
    onSuccess: (data) => {
      if (!data) return;
      queryClient.clear();
      queryClient.setQueryData([QUERY_KEYS.USER], data);
    },
    onError
  });
};

export const useRegisterAccount = (onSuccess?: () => void, onError?: (err: unknown) => void) =>
  useMutation({
    mutationFn: (data: IRegisterUserPayload) => authService.signUp(data),
    onSuccess,
    onError
  });

export const useSendRestorePasswordLink = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IRecoveryPassword) =>
      authService.sendRecoverPasswordLink({
        email: data.email,
        redirectUri: window.location.origin + ROUTER_KEYS.AUTH
      }),
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess();
      }
      if (!data) return;
      queryClient.setQueryData([QUERY_KEYS.USER], data);
    }
  });
};

export const useEmailCharactersLoginSend = () =>
  useMutation({
    mutationFn: (data: IPasswordlessRequestPayload) => authService.emailCharactersSigninSend(data)
  });

export const useEmailCharactersLoginConfirm = (onError?: (err: unknown) => void, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IPasswordlessSendCodePayload) => authService.emailCharactersSigninConfirm(data),
    onSuccess: (data) => {
      if (!data) return;
      queryClient.setQueryData([QUERY_KEYS.USER], data);
      if (onSuccess) onSuccess();
    },
    onError
  });
};

export const useAnonSignIn = (onSuccess: () => void, onError?: (err: unknown) => void) => {
  const { push, reload } = useRouter();
  return useMutation({
    mutationFn: (keyword: string) => authToAnonService.anonSignIn(keyword),
    onSuccess: async () => {
      onSuccess();
      await push(ROUTER_KEYS.HOME);
      reload();
    },
    onError
  });
};

export const useVerifyAnonEmailSend = (onSuccess: () => void, onError: (err: unknown) => void) =>
  useMutation({
    mutationFn: (data: IPasswordlessRequestPayload) => authService.verifyAnonEmail(data),
    onSuccess,
    onError
  });

export const useVerifyAnonEmailConfirm = (onSuccess: () => void, onError: (err: unknown) => void) =>
  useMutation({
    mutationFn: (data: IPasswordlessSendCodePayload) => authService.verifyAnonEmialConfirm(data),
    onSuccess,
    onError
  });

export const useResetAnonKeyword = (onSuccess: () => void, onError: (err: unknown) => void) => {
  const { push, reload } = useRouter();
  return useMutation({
    mutationFn: (data: IResetAnonKeywordRequestPayload) => authService.resetAnonKeyword(data),
    onSuccess: async () => {
      onSuccess();
      await push(ROUTER_KEYS.HOME);
      reload();
    },
    onError
  });
};

export const useSwitchToReacAcount = () => {
  const { push, reload } = useRouter();
  const { mutateAsync: logout } = useLogount();
  return useMutation({
    mutationFn: () => authService.switchToRealFromAnon(),
    onSuccess: async () => {
      await push(ROUTER_KEYS.HOME);
      reload();
    },
    onError: () => {
      logout();
    }
  });
};

export const useSwitchToSimplifiedAnonAcount = () => {
  const { push, reload } = useRouter();
  const { mutateAsync: logout } = useLogount();
  return useMutation({
    mutationFn: () => authService.switchToSimplifiedAnon(),
    onSuccess: async () => {
      await push(ROUTER_KEYS.HOME);
      reload();
    },
    onError: () => {
      logout();
    }
  });
};

export const useDeleteRealAfetrAnon = () => {
  const { reload } = useRouter();
  const { mutateAsync: logout } = useLogount();
  return useMutation({
    mutationFn: () => authService.deleteRealAfterCreateFromAnon(),
    onSuccess: async () => {
      reload();
    },
    onError: () => {
      logout();
    }
  });
};

export const useRealVerifySend = (onSuccess?: () => void, onError?: (err: unknown) => void) =>
  useMutation({
    mutationFn: async (data: IEmailVerifySendPayload) => {
      const response = await authService.realVerifySend(data);
      return response;
    },
    onSuccess,
    onError
  });

export const useRealVerifyConfirm = (onSuccess: () => void, onError?: (err: unknown) => void) => {
  const { push, reload } = useRouter();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IEmailVerifyConfirmPayload) => {
      const response = await authService.realVerifyConfirm(data);
      return response;
    },
    onSuccess: async (data) => {
      onSuccess();
      await push(ROUTER_KEYS.HOME);
      reload();
      if (!data) return;
      queryClient.setQueryData([QUERY_KEYS.USER], data);
    },
    onError
  });
};

export const useChangeEmailSend = ({ onError, onSuccess }: { onSuccess?: () => void; onError?: (err: unknown) => void }) =>
  useMutation({
    mutationFn: async (data: IEmailVerifySendPayload) => authService.changeEmailSend(data),
    onSuccess,
    onError
  });

export const useChangeEmailConfirm = ({ onError, onSuccess }: { onSuccess?: () => void; onError?: (err: unknown) => void }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IEmailVerifyConfirmPayload) => {
      const response = await authService.changeEmailConfirm(data);
      return response;
    },
    onSuccess: (data) => {
      if (onSuccess) onSuccess();
      if (!data) return;
      queryClient.setQueryData([QUERY_KEYS.USER], data);
    },
    onError
  });
};
