import { deleteCookie } from 'cookies-next';
import { HttpService } from './http.service';
import { IUser, IUserPayload } from '@/common/types/user.type';
import {
  IPasswordlessRequestPayload,
  IPasswordlessSendCodePayload,
  IRecoveryPayload,
  IRegisterUserPayload,
  IResetAnonKeywordRequestPayload,
  ISetupPassword,
  ISetupPasswordPayload,
  ISignIn
} from '@/common/types/auth.type';
import { HEADERS_KEYS, TOKEN_KEYS } from '@/common/consts/app-keys.const';
import { axiosInstance } from '@/common/services/axios';
import { IEmailVerifyConfirmPayload, IEmailVerifySendPayload } from '../types/anon.types';

// fix
class AuthService extends HttpService {
  constructor() {
    super(axiosInstance);
  }

  async signUp(payload: IRegisterUserPayload) {
    return this.post<void, IRegisterUserPayload>('auth/register', payload);
  }

  async signIn(payload: IUserPayload) {
    const response = await this.post<ISignIn, IUserPayload>('auth/signin', payload);

    return response;
  }

  async verifyAccount(token: string) {
    const response = await this.post<ISignIn, { token: string }>('auth/verify', { token });

    return response;
  }

  async sendRecoverPasswordLink(payload: IRecoveryPayload) {
    return this.post<void, IRecoveryPayload>('auth/recover-password', payload);
  }

  async updatePassword(payload: ISetupPasswordPayload) {
    return this.patch<IUser, ISetupPasswordPayload>('auth/recover-password', payload);
  }

  async updatePasswordFromSettings(payload: ISetupPassword) {
    return this.patch<IUser, ISetupPassword>('auth/recover-password-from-setting', payload);
  }

  async check() {
    const checkData = await this.get<boolean>('auth/check');
    if (typeof checkData === 'undefined') {
      return false;
    }
    return checkData;
  }

  async refreshAccessToken() {
    const response = await this.get<{ accessToken: string }>('auth/refresh');
    return response;
  }

  async emailCharactersSigninSend(payload: IPasswordlessRequestPayload) {
    return this.post<{ charactersCodeSent?: boolean; isLinkMethod?: boolean; isPassword?: boolean }, IPasswordlessRequestPayload>(
      'auth/email-characters-signin/send',
      payload
    );
  }

  async emailCharactersSigninConfirm(payload: IPasswordlessSendCodePayload) {
    const response = await this.post<ISignIn, IPasswordlessSendCodePayload>('auth/email-characters-signin/confirm', payload);
    return response;
  }

  async logout() {
    await this.get('auth/logout');
    deleteCookie(TOKEN_KEYS.ACCESS_TOKEN);
    deleteCookie(TOKEN_KEYS.REFRESH_TOKEN);
    deleteCookie(HEADERS_KEYS.REFRESH_TOKEN_REAL);
  }

  async anonSignIn(keyword: string) {
    const response = await this.post<ISignIn, { keyword: string }>('auth/anon-sign-in', { keyword });

    return response;
  }

  async verifyAnonEmail(payload: IPasswordlessRequestPayload) {
    return this.post<boolean, IPasswordlessRequestPayload>('auth/verify-anon-email/send', payload);
  }

  async verifyAnonEmialConfirm(payload: IPasswordlessSendCodePayload) {
    return this.post<void, IPasswordlessSendCodePayload>('auth/verify-anon-email/confirm', payload);
  }

  async realVerifySend(payload: IEmailVerifySendPayload) {
    return this.post<boolean, IEmailVerifySendPayload>('auth/real-verify/send', payload);
  }

  async realVerifyConfirm(payload: IEmailVerifyConfirmPayload) {
    return this.post<ISignIn, IEmailVerifyConfirmPayload>('auth/real-verify/confirm', payload);
  }

  async resetAnonKeyword(payload: IResetAnonKeywordRequestPayload) {
    return this.post<void, IResetAnonKeywordRequestPayload>('auth/reset-anon-keyword', payload);
  }

  async switchToRealFromAnon() {
    return this.get<ISignIn>('auth/switch-to-real');
  }

  async switchToSimplifiedAnon() {
    return this.get<ISignIn>('auth/switch-to-simplified-anon');
  }

  async deleteRealAfterCreateFromAnon() {
    return this.delete('auth/delete-real-user-after-create-from-anon');
  }

  async changeEmailSend(payload: IEmailVerifySendPayload) {
    return this.post<boolean, IEmailVerifySendPayload>('auth/change-email/send', payload);
  }

  async changeEmailConfirm(payload: IEmailVerifyConfirmPayload) {
    return this.post<boolean, IEmailVerifyConfirmPayload>('auth/change-email/confirm', payload);
  }

  async triedToActivate() {
    return this.get('auth/tried-to-activate')
  }
}

export const authService = new AuthService();
