export enum NewsfeedCategory {
  SOURCE = 'source',
  TIME = 'time',
  TOPICS = 'topics',
  SECTORS = 'sectors',
  JOB_VERTICALS = 'job verticals',
  REGIONS = 'regions'
}

export interface IFilters {
  [NewsfeedCategory.SOURCE]: ITagFilter[];
  [NewsfeedCategory.TIME]: ITagFilter[];
  [NewsfeedCategory.TOPICS]: ITagFilter[];
  [NewsfeedCategory.SECTORS]: ITagFilter[];
  [NewsfeedCategory.JOB_VERTICALS]: ITagFilter[];
  [NewsfeedCategory.REGIONS]: ITagFilter[];
}

export interface ITagFilter {
  id: string;
  shortName: string;
  fullName: string;
  include: boolean;
  type: string;
}

export interface INewsfeedFilter {
  updateFilters: <T extends keyof IFilters>(filterName: T, filterValue: ITagFilter) => void;
  currentFilter: IFilters;
  tagList?: IListTags;
  allowedFilters?: ITag[];
  isAnon: boolean;
}

export interface IListTags {
  [key: string]: ITag[];
}

export interface ITag {
  id: string;
  fullName: string;
  shortName: string;
  type: string;
}
export interface INewsfeedListFilter {
  updateFilters: <T extends keyof IFilters>(filterName: T, filterValue: ITagFilter) => void;
  currentFilter: ITagFilter[];
  tagList: ITag[];
  tagName: NewsfeedCategory;
  displayTagName?: string;
  allowedFilters?: ITag[];
  isFilterLoading?: boolean;
  isAnon: boolean;
}

export enum NewsfeedNavigation {
  UNREAD = 'unread',
  SAVED = 'saved',
  SKIPPED = 'skipped'
}

export interface ISkipPayload {
  postsId: string[];
}
